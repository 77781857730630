<template>
  <div>
    <section class="subpage-bg">
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-md-10">
            <div class="titile-block title-block_subpage">
              <h2>{{ $t("catalog") }}</h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--// SUBPAGE HEADER BG -->
    <!--============================= CATALOGS =============================-->
    <!--============================= CATALOGS =============================-->
 
           


                <div>
                  <div class="d-flex justify-content-center row">
                    <div class="p-4">
                      <a href="/assets/images/Technigor-catalog.pdf" target="_blank">
                        <img src="/assets/images/article-img2.png" alt="#" class="img-fluid" />
                        <div class="news-title border">
                          <p>{{ $t("catalog-PL") }}</p>
                          <h5>{{ $t("technigor") }}</h5>
                          <p>2025</p>
                        </div>
                      </a>
                    </div>  
                    <div class="p-4">  
                      <a href="/assets/images/Technigor-catalogEN.pdf" target="_blank">
                        <img src="/assets/images/article-img3.png" alt="#" class="img-fluid" />
                        <div class="news-title border">
                          <p>{{ $t("catalog-EN") }}</p>
                          <h5>{{ $t("technigor") }}</h5>
                          <p>2025</p>
                        </div>
                      </a>
                    </div>  
                  </div>     
                </div>
  </div>
</template>

<script>
export default {
  name: "Catalogs"
};
</script>

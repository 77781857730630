<template>
  <div>
    <section class="subpage-bg">
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-md-10">
            <div class="titile-block title-block_subpage">
              <h2>{{ $t("certificates") }}</h2>
            </div>
          </div>
        </div>
      </div>
    </section>
      <div class=" main-block">
        <div class="col-12">
          <div class="container">
            <h2 >{{ $t("certificates") }}</h2>
            <p class="text-justify">{{ $t("certificates_text") }}</p>
          </div>
        </div>
      </div>  
      <div>
        <div class="d-flex justify-content-center row">
          <div class="p-4">
            <a href="/assets/images/Iso_pl.pdf" target="_blank">
              <img src="/assets/images/certificate-pl.png" alt="#" class="img-fluid" />
              <div class="news-title border">
                <p>Certifikat PL</p>
                  <h5>Standard ISO 9001:2015</h5>
                  <p>2025</p>
              </div>
            </a>
          </div>  
          <div class="p-4">  
            <a href="/assets/images/Iso_en.pdf" target="_blank">
              <img src="/assets/images/certificate-en.png" alt="#" class="img-fluid" />
              <div class="news-title border">
                <p>Certificate EN</p>
                  <h5>Standard ISO 9001:2015</h5>
                  <p>2025</p>
              </div>
            </a>
          </div>  
        </div>     
      </div>
    <section class="  ">
      
      <div class="">

          <div class="row d-flex justify-content-center">
            <div class="p-4">
              <a
                href="http://www.certipedia.com/quality_marks/9108643177?locale=pl"
                target="_blank"
              >
                <img
                  src="http://www.certipedia.com/quality_marks/9108643177/logo?locale=pl"
              /></a>
            </div> 
            <div class="p-4"> 
              <a
                href="http://www.certipedia.com/quality_marks/9108643177?locale=en"
                target="_blank"
              >
                <img
                  src="http://www.certipedia.com/quality_marks/9108643177/logo?locale=en"
              /></a>
            </div>      
          </div>
          <div class=" main-block">
            <div class="col-12">
              <div class="container">
                <h2>{{ $t("certificates-polityka") }}</h2>
                  <br />
                  <div class="d-flex justify-content-center row">
                      <div class="p-4">
                        <a href="/assets/images/polityka_jakosci.pdf" target="_blank">
                          <img src="/assets/images/polityka_jakosci.png" alt="#" class="img-fluid" />
                          <div class="news-title border">
                            <p>Technigor Rock Bits Sp. z o. o.</p>
                              <h5>Polityka Jakości</h5>
                              <p>2025</p>
                          </div>
                        </a>
                      </div>    
                    </div>     
                  <!-- <p class="text-justify">
                    {{ $t("certificates-polityka1") }}<br /><br />{{
                      $t("certificates-polityka2")
                    }}<br />
                    {{ $t("certificates-polityka3") }}<br />
                    {{ $t("certificates-polityka4") }}
                  </p> -->
              </div>
            </div>
          </div>  
          
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Certificates"
};
</script>
